/**
 * CkDatePicker Component
 *
 * `CkDatePicker` is a custom Date Picker component built upon Ant Design's DatePicker and Form.Item components.
 * This component provides extra customization options such as defining the theme, variant, and other properties of the DatePicker and Form Item.
 *
 * @component
 * @example
 * // This is how you can use the CkDatePicker component with light theme and rounded variant
 * <CkDatePicker variant="rounded" />
 *
 * @extends {FormItemProps}
 *
 * @property {"default"|"rounded"} variant - Optional: Determines the style of the date picker, defaults to "default"
 * @property {DatePickerProps} inputProps - Optional: Additional properties to pass to the Ant Design DatePicker component
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {boolean} allowClear - Optional: Determines whether the date picker input can be cleared
 * @property {string} popupClassName - Optional: Additional CSS classes to apply to the popup of the date picker
 */
import React, { FC } from "react";
import { DatePicker, Form, FormItemProps } from "antd";
import { DatePickerProps } from 'antd/lib/date-picker';
import { CkIcon } from "../../atoms";
import "./styles.css";

export interface CkDatePickerProps extends FormItemProps {
  /**
   * Optional: Determines the style of the date picker, defaults to "default"
   */
  variant?: "default" | "rounded";
  /**
   * Optional: Additional properties to pass to the Ant Design DatePicker component
   */
  inputProps?: DatePickerProps;
  /**
   * Optional: Additional CSS classes to apply to form item
   */
  className?: string;
  /**
   * Optional: Determines whether the date picker input can be cleared
   */
  allowClear?: boolean;
  /**
   * Optional: Additional CSS classes to apply to the popup of the date picker
   */
  popupClassName?: string;
}
/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * inputProps extends AntD Date Picker, see full docs on https://4x.ant.design/components/date-picker/
 * does not work for Date Range Picker, yet
 */
const CkDatePicker: FC<CkDatePickerProps> = ({ variant = "default", allowClear, popupClassName,className, inputProps, ...props}) => (
  <Form.Item 
    className={["ck-datepicker", variant, ...(className ? [className] : [])].join(" ")}
    {...props}
  >
    <DatePicker 
      getPopupContainer={(e) => e}
      popupClassName={["ck-datepicker-popover", variant, ...(popupClassName ? [popupClassName] : [])].join(" ")}
      suffixIcon={<CkIcon name="calendar" />}
      {...(inputProps || {})} 
    />
  </Form.Item>
)

export default CkDatePicker;
