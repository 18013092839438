/**
 * CkSwitchText Component
 *
 * This is a wrapper of the Switch component from the 'react-switch' library. CkSwitchText provides a more customised Switch component, including extra texts on both sides of the switch.
 * The `className`, `leftText` and `rightText` are unique additional properties to this component.
 * The other properties inherited from 'Switch' component can be passed in `props`.
 *
 * @component
 * @example
 * // This is how you can use the CkSwitchText component with additional class name, and passing text to display on either side of switch
 * <CkSwitchText className="dark-mode" leftText="Light Mode" rightText="Dark Mode"/>
 *
 * @extends {ReactSwitchProps}
 * 
 * @property {string} className - Optional: Class name for the component from parent
 * @property {string} leftText - Required: Text displayed when the switch is on
 * @property {string} rightText - Required: Text displayed when the switch is off
 */
import React, { FC } from "react";
import { ReactSwitchProps } from 'react-switch';
import Switch from "react-switch";
import "./styles.css";

export interface CkSwitchTextProps extends ReactSwitchProps {
  /**
   * className - Optional: Class name for the component from parent
   */
  className?: string;
  /**
   * leftText - Required: Text displayed when the switch is on
   */
  leftText: string;
  /**
   * rightText - Required: Text displayed when the switch is off
   */
  rightText: string;
}

/**
 * Extends Switch from react-switch props
 * See full doc on https://www.npmjs.com/package/react-switch
 * This line will be required to work properly
 * => className={["search-by-switch",...(searchBySwitch ? ["checked"] : [""]),].join(" ")}
 */
const CkSwitchText : FC<CkSwitchTextProps> = ({className, leftText, rightText, ...props}) => (
  <Switch 
    className={["ck-switch-text", ...(className ? [className] : [])].join(" ")}
    checkedIcon={<p>{leftText}</p>}
    checkedHandleIcon={<p>{rightText}</p>}
    uncheckedIcon={<p>{rightText}</p>}
    uncheckedHandleIcon={<p>{leftText}</p>}
    height={40}
    width={282}
    borderRadius={4}
    handleDiameter={40}
    activeBoxShadow={""}
    {...props}
  />
)

export default CkSwitchText;
