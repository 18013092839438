/**
 * CkAddButton Component
 *
 * The `CkAddButton` component is a wrapper around Ant Design's `Button` component that provides a more customized Button. It accepts all the properties of the AntDesign `Button` and some additional properties like `icon`, which can be a name of an icon or a `ReactNode`, and `triggerGTMOnClick`, which let you trigger a Google Tag Manager event when the button is clicked.
 *
 * @component
 * @example
 * <CkAddButton 
 *  icon="calendar-grid"
 *  triggerGTMOnClick={true}
 * >
 *   Click Here
 * </CkAddButton>
 *
 * @extends {ButtonProps}
 *
 * @property {ReactNode} children - Optional: Content to display inside the button
 * @property {string | ReactNode} icon - Optional: Either a CkIcon name or a custom icon element
 * @property {boolean} triggerGTMOnClick - Optional: Determines whether to trigger a Google Tag Manager event on click.
 * @property {string} className - Optional: Class name from parent added to the default "ck-add-button" class name
 * @property {Function} onClick - Optional: Event Listener for click events on the button
 * @property {...ButtonProps} props - Other properties from the parent Button component
 */
import React, { FC, ReactNode } from "react";
import { Button, ButtonProps } from "antd";
import { CkIcon, CkIconNames } from "../../atoms";
import { gtmSetId } from "../../../utils";
import "./styles.css";

export interface CkAddButtonProps extends ButtonProps {
  /**
   * Optional: Content to display inside the button
   */
  children?: ReactNode;
  /**
   * Optional: if this value isn't provided, "plus-add" will be used by default. 
   * If a string is provided, component will try to import CkIcon element. 
   * If ReactNode is provided, this will be used.
   */
  icon?: CkIconNames | ReactNode;
  /**
   * Optional: Determines whether to trigger a Google Tag Manager event on click.
   */
  triggerGTMOnClick?: boolean;
}

/**
 * Extends AntD button props
 * See full doc on https://4x.ant.design/components/button/
 */
const CkAddButton: FC<CkAddButtonProps> = ({
  children,
  icon,
  className,
  triggerGTMOnClick,
  ...props
}) => (
  <Button
    className={[
      "ck-add-button",
      ...(className ? [className] : []),
    ].join(" ")}
    {...(typeof icon === "undefined" ? { icon: <CkIcon name={"plus-add"} /> } : {})}
    {...(icon && typeof icon === "string"
      ? { icon: <CkIcon name={icon as CkIconNames} /> }
      : {})}
    {...(icon && typeof icon !== "string" ? { icon } : {})}
    {...(props.id && triggerGTMOnClick
      ? {
          onClick: (e) => {
            gtmSetId(e.currentTarget);
            props?.onClick && props.onClick(e);
          },
        }
      : {})}
    {...props}
  >
    {children && children}
  </Button>
);

export default CkAddButton;
